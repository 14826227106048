@import 'mixins/global';

body{
	font-family:"rubik-regular";
	font-size:13px;
	line-height:16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $colorMarine;
	&.overflowed{
		overflow: hidden;
	}
}

#CopyTextInput{
	position:absolute;
	left: 0px;
	top: 0px;
	z-index: -1;
	visibility: none;
	opacity: 0;
}

#BurgerMenuBt{
	display: none;
}

.link{
	color: $colorMarine;
	text-decoration: underline;
	cursor: pointer;
	&:hover{
		color:$colorCyan;
	}
}

.network_bt[data-network="lk"]{
	position: relative;
	.lk_tooltip{
		position: absolute;
		left: 50%;
		bottom: 100%;
		margin-bottom: 4px;
		@include transform(translate(-50%, 20px));
		opacity: 0;
		@include transition((all), 0.6s, ease-in-out);
	}
	&.clipboarded{
		.lk_tooltip{
			@include transform(translate(-50%, 0px));
			opacity: 1;
		}
	}
	&.clipboarded_after{
		.lk_tooltip{
			opacity: 0;
		}
	}
}

#App{
	text-align: center;
	margin-top: 20px;
	#Sidebar{
		width: 272px;
		position: fixed;
		z-index: 1;
		top: 20px;
		left: 50%;
		height: 400px;
		display: block;
		@include transform(translate(-50%, 0));
		margin-left: -450px;
		.sidebar_logo{
			background:url("images/logo-affichesclimatfr@2x.png") no-repeat 0 0;
			width: 272px;
			height: 200px;
			background-size: 100% 100%;
		}
		.sidebar_sharebar{
			width: 272px;
			height: 61px;
			background:url("images/bg-bloc@2x.png") no-repeat 0 0;
			background-size: 100% 100%;
			margin-top: 10px;
			color: #fff;
			line-height: 61px;
			text-align: left;
			padding-left: 10px;
			border-radius: 5px;
			.sharebar_title{
				font-size: 16px;
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
				font-family: "rubik-medium";
			}
			.sharebar_link{
				width: 32px;
				height: 32px;
				border-radius: 32px;
				background: #fff;
				display: inline-block;
				vertical-align: middle;
				margin-left: 5px;
				cursor: pointer;
				opacity: 1;
				position: relative;
				@include transition((opacity), 0.2s, ease-in-out);
				&:after{
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					width: 100%;
					height: 100%;
					background-size: 100% 100%;
					@include transform(translate(-50%, -50%));
				}
				&[data-link="tw"]{
					&:after{
						background: url("images/icon-blue-twt.svg") no-repeat center center;
						background-size: 100% 100%;
					}
					
				}
				&[data-link="fb"]{
					&:after{
						background: url("images/icon-blue-fb.svg") no-repeat center center;
						background-size: 100% 100%;
					}
				}
				&:hover{
					opacity: 0.6;
				}
			}
		}
		.sidebar_help{
			text-align: left;
			margin-top: 20px;
			.help_title{
				font-size: 16px;
				font-family: "rubik-medium";
			}
			.help_indications{
				text-align: left;
				margin-top: 20px;
				.indications_figure{
					font-size: 58px;
					display: inline-block;
					vertical-align: top;
					font-family: "garagegothic-black";
					width: 30px;
					position: relative;
					top: 15px;
				}
				.indications_text{
					font-size: 14px;
					display: inline-block;
					vertical-align: middle;
					line-height: 16px;
					width: 230px;
				}
			}
		}

		.sidebar_footer{
			position: relative;
			width: 272px;
			margin-top: 14px;
			padding: 30px 20px 0;
			text-align: left;
			&:before{
				content: "";
				position: absolute;
				left: 0px;
				top: 0px;
				width: 272px;
				height: 23px;
				border-radius: 5px;
				background:url("images/bg-bloc@2x.png") no-repeat 0 center;
				background-size: 100% 100%;
			}
			.footer_text{
				font-size: 16px;
				line-height: 20px;
				font-family: "rubik-medium";
				.text_conception{
					display: inline-block;
					vertical-align: top;
				}
				.text_logo{
					display: inline-block;
					vertical-align: top;
					background:url("images/logo-wdd.svg") no-repeat 0 0;
					width: 122px;
					height: 16px;
					background-size: 100% 100%;
					cursor: pointer;
					text-decoration: none;
					&:hover{
						background:url("images/logo-wdd-cyan.svg") no-repeat 0 0;
					}
				}
			}
			.footer_link{
				font-size: 16px;
				font-family: "rubik-medium";
				line-height: 24px;
				color:$colorMarine;
				text-decoration: underline;
				cursor: pointer;
				&:hover{
					color: #2578cc;
				}
			}
		}
	}

	#Main{
		width: 908px;
		position: relative;
		display: inline-block;
		height: 100px;
		margin-left: 310px;
		.main_title{
			font-size: 36px;
			line-height: 37px;
			color: $colorMarine;
			padding: 0 12px;
			text-align: left;
			max-width: 780px;
			font-family: "garagegothic-regular";
		}
		.main_sharebarmobile{
			display: none;
		}
		.main_affiches{
			margin-bottom: 80px;
			text-align: center;
			.affiche{
				position: relative;
				cursor: pointer;
				display: inline-block;
				vertical-align: top;
				box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
				&:before{
					content: "";
					position: absolute;
					left: -10px;
					top: -10px;
					right: -10px;
					bottom: -10px;
					border: 10px solid $colorMarine;
					display: none;
					box-shadow:  0px 0px 12px 0px rgba(0, 0, 0, 0.7);
				}
				&[data-mode="landscape"]{
					width: 880px;
					height: 622px;
					margin-top: 20px;
				}
				&[data-mode="portrait"]{
					width: 426px;
					height: 602px;
					margin: 20px 14px 0px;
				}
				.affiche_img{
					width: 100%;
					height: 100%;
					img{
						width: 100%;
					}
				}
				.affiche_sharebar{
					position: absolute;
					bottom: 0px;
					left: 0px;
					width: 100%;
					height: 80px;
					background: #fff;
					display: none;
					font-family: "rubik-medium";
					.sharebar_dlbt{
						color: $colorMarine;
						font-family: "rubik-medium";
						font-size: 16px;
						display: block;
						position: absolute;
						left: 15px;
						top: 50%;
						width: 42px;
						height: 42px;
						background:$colorMarine;
						border-radius: 42px;
						text-decoration: none;
						@include transform(translate(0, -50%));
						@include transition((all), 0.2s, ease-in-out);
						&:before{
							position: absolute;
							left: 50%;
							top: 50%;
							@include transform(translate(-50%, -50%));
							width: 100%;
							height: 100%;
							@include transition((all), 0.2s, ease-in-out);
							background: url("images/icon-white-dl.svg") no-repeat center center transparent;
							background-size: 100% 100%;
							content: "";
						}
						&:hover{
							color:$colorCyan;
							background: $colorCyan;
						}
						.dlbt_text{
							position: absolute;
							left: 100%;
							top: 50%;
							margin-left: 10px;
							@include transform(translate(0, -50%));
						}
					}
					.sharebar_networks{
						position: absolute;
						right: 10px;
						top: 50%;
						@include transform(translate(0, -50%));
						.network_bt{
							width: 42px;
							height: 42px;
							border-radius: 42px;
							background: $colorMarine;
							display: inline-block;
							vertical-align: top;
							margin: 0 5px;
							cursor: pointer;
							position: relative;
							@include transition((all), 0.2s, ease-in-out);
							&:before{
								content:"";
								position: absolute;
								left: 50%;
								top: 50%;
								width: 100%;
								height: 100%;
								@include transform(translate(-50%, -50%));
							}
							&[data-network="tw"]{
								&:before{
									background: url("images/icon-white-twt.svg") no-repeat center center transparent;
									background-size: 100% 100%;
								}
								&:hover{
									background: $colorCyan;
								}
							}
							&[data-network="fb"]{
								&:before{
									background: url("images/icon-white-fb.svg") no-repeat center center transparent;
									background-size: 100% 100%;
								}
								&:hover{
									background: $colorCyan;
								}
							}
							&[data-network="lk"]{
								&:before{
									background: url("images/icon-white-link.svg") no-repeat center center transparent;
									background-size: 100% 100%;
								}
								&:hover{
									background: $colorCyan;
								}
							}
						}
					}
				}
				&:hover{
					&:before{
						display: block;
					}
					.affiche_sharebar{
						display: block;
					}
				}
			}
		}
	}
}