@import 'mixins/global';

.modal_bg{
	background:rgba(33,53,101,0.7);
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
}

#AfficheModalWrapper{
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: none;
	opacity: 0;
	z-index: -1;
	text-align: center;
	overflow: auto;
	@include transition((all), 0.6s, ease-in-out);
	&.domed{
		display: block;
		z-index: 100000;
	}
	&.displayed{
		opacity: 1;
	}

	#AfficheModal{
		position: absolute;
		left: 50%;
		z-index: 10;
		top: 200%;
		width: 90%;
		height: 90%;
		@include transform(translate(-50%, -50%));
		@include transition((all), 0.6s, ease-in-out);
		&.fadein{
			top: 55%;
		}
		.modal_closebt{
			display: none;
		}
		.affichemodal_title{
			font-size: 12px;
			color: #fff;
			text-align: left;
			position: absolute;
			left: 50%;
			min-width: 300px;
			bottom: 100%;
			@include transform(translate(-50%, 0));
		}
		.affichemodal_img{
			height: 80%;
			min-width: 300px;
		}
		.affichemodal_src{
			position: absolute;
			left: 50%;
			color: #fff;
			bottom: 20%;
			max-width: 250px;
			min-width: 130px;
			@include transform(translate(10px, 0));
			text-align: left;
			a{
				color: #fff;
				text-decoration: underline;
				cursor: pointer;
			}
		}
		.affichemodal_bar{
			position: absolute;
			left: 50%;
			top: 85%;
			@include transform(translate(-50%, 0));
			.sharebar_dlbt{
				color: #fff;
				font-family: "rubik-medium";
				font-size: 16px;
				display: block;
				position: absolute;
				left: 0px;
				top: 50%;
				@include transform(translate(0, -50%));
				text-decoration: none;
				width: 42px;
				height: 42px;
				background: #fff;
				border-radius: 42px;
				cursor: pointer;
				@include transition((all), 0.2s, ease-in-out);
				&:before{
					position: absolute;
					left: 50%;
					top: 50%;
					@include transform(translate(-50%, -50%));
					@include transition((all), 0.2s, ease-in-out);
					background: url("images/icon-blue-dl.svg") no-repeat center center transparent;
					background-size: 100% 100%;
					width: 100%;
					height: 100%;
					content: "";
				}
				&:hover{
					color:#a6aec1;
					background: #fff;
				}
				.dlbt_text{
					position: absolute;
					left: 100%;
					top: 50%;
					margin-left: 10px;
					@include transform(translate(0, -50%));
				}
			}
			.sharebar_networks{
				position: absolute;
				right: 0px;
				top: 50%;
				@include transform(translate(0, -50%));
				.network_bt{
					width: 42px;
					height: 42px;
					border-radius: 42px;
					background: $colorMarine;
					display: inline-block;
					vertical-align: top;
					margin: 0 5px;
					cursor: pointer;
					@include transition((all), 0.2s, ease-in-out);
					background: #fff;
					position: relative;
					&:after{
						content: "";
						position: absolute;
						left: 50%;
						top: 50%;
						@include transform(translate(-50%, -50%));
						width: 100%;
						height: 100%;
					}
					&[data-network="tw"]{
						&:after{
							background: url("images/icon-blue-twt.svg") no-repeat center center transparent;
							background-size: 100% 100%;
						}
						&:hover{
							background: #a6aec1;
						}
					}
					&[data-network="fb"]{
						&:after{
							background: url("images/icon-blue-fb.svg") no-repeat center center transparent;
							background-size: 100% 100%;
						}
						&:hover{
									background: #a6aec1;
								}
					}
					&[data-network="lk"]{
						color: #fff;
						&:after{
							background: url("images/icon-blue-link.svg") no-repeat center center transparent;
							background-size: 100% 100%;
						}
						&:hover{
									background: #a6aec1;
								}
					}
				}
			}
		}
	}
}

#AboutModalWrapper{
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: none;
	opacity: 0;
	z-index: -1;
	text-align: center;
	@include transition((all), 0.6s, ease-in-out);
	&.domed{
		display: block;
		z-index: 100000;
	}
	&.displayed{
		opacity: 1;
	}

	#AboutModal{
		position: absolute;
		left: 50%;
		top: 300%;
		width: 582px;
		min-height: 433px;
		border-radius: 5px;
		z-index: 10;
 		padding: 20px 60px 40px;
		text-align: center;
		@include transform(translate(-50%, -50%));
		@include transition((all), 0.6s, ease-in-out);

		&.fadein{
			top: 50%;
		}

		&:before{
			content: "";
			position: absolute;
			left: 0px;
			bottom: 0px;
			border-radius: 5px;
			background-size: 100% 100%;
			background: url("images/bg-popin@2x.png") no-repeat 0 0;
			width: 582px;
			height: 100%;
			z-index: 0;
		}

		.aboutmodal_logo{
			background:url("images/logo-affichesclimatfr@2x.png") no-repeat center center;
			background-size: 100% 100%;
			width: 181px;
			height: 133px;
			display: inline-block;
			vertical-align: top;
			position: relative;
		}

		.aboutmodal_text{
			font-size: 16px;
			line-height: 18px;
			padding-top: 8px;
			display: inline-block;
			vertical-align: top;
			text-align: left;
			margin-top: 20px;
			position: relative;
			.text_bigger{
				font-size: 18px;
				font-family: "rubik-medium";
				padding-top: 16px;
				padding-bottom: 8px;
			}
		}
	}
}

#AboutMobileModalWrapper{
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 1000;
	width: 100%;
	background: #fff;
	display: none;
	opacity: 0;
	height: 100%;
	overflow: auto;
	z-index: -1;
	text-align: center;
	@include transition((all), 0.6s, ease-in-out);
	&.domed{
		display: block;
		z-index: 100000;
	}
	&.displayed{
		opacity: 1;
	}

	#AboutMobileModal{
		margin-top: 40px;
	}

	.modal_closebt{
		display: block;
		cursor: pointer;
		position: absolute;
		left: 10px;
		top: 20px;
		background:url("images/icon-blue-close.svg") no-repeat 0 0;
		background-size: 100% 100%;
		width: 28px;
		height: 28px;
	}

	.aboutmodal_logo{
		background:url("images/logo-affichesclimatfr@2x.png") no-repeat center center;
		background-size: 100% 100%;
		width: 181px;
		height: 133px;
		display: inline-block;
		vertical-align: top;
		position: relative;
	}

	.help_title{
		font-size: 16px;
		font-family: "rubik-medium";
		margin-top: 40px;
	}
	.help_indications{
		text-align: center;
		margin-top: 20px;
		.indications_figure{
			font-size: 58px;
			display: inline-block;
			vertical-align: middle;
			font-family: "garagegothic-black";
			width: 30px;
		}
		.indications_text{
			font-size: 14px;
			display: inline-block;
			vertical-align: middle;
			line-height: 16px;
			width: 230px;
			text-align: left;
		}
	}

	.footer_text{
		font-size: 16px;
		line-height: 20px;
		font-family: "rubik-medium";
		margin-top: 60px;
		.text_conception{
			display: inline-block;
			vertical-align: top;
		}
		.text_logo{
			display: inline-block;
			vertical-align: top;
			background:url("images/logo-wdd.svg") no-repeat 0 0;
			width: 122px;
			height: 16px;
			background-size: 100% 100%;
			cursor: pointer;
			text-decoration: none;
			&:hover{
				background:url("images/logo-wdd-cyan.svg") no-repeat 0 0;
			}
		}
	}

	.aboutmodal_title{
		font-family: "rubik-medium";
		font-size: 16px;
		line-height:22px;
		margin-top: 60px;
	}

	.aboutmodal_text{
		font-family: "rubik-regular";
		font-size: 16px;
		line-height: 18px;
		display: inline-block;
		vertical-align: top;
		text-align: left;
		margin-top: 20px;
		position: relative;
		padding: 0 60px;
		margin-bottom: 100px;
		.text_bigger{
			font-size: 18px;
			font-family: "rubik-medium";
			padding-top: 16px;
			padding-bottom: 8px;
		}
	}
}