/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 12, 2017 */



@font-face {
    font-family: 'rubik-medium';
    src: url('fonts/rubik-medium.woff2') format('woff2'),
         url('fonts/rubik-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'rubik-regular';
    src: url('fonts/rubik-regular.woff2') format('woff2'),
         url('fonts/rubik-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'garagegothic-regular';
    src: url('fonts/garagegothic-regular.woff2') format('woff2'),
         url('fonts/garagegothic-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 12, 2017 */



@font-face {
    font-family: 'garagegothic-black';
    src: url('fonts/garagegothic-black.woff2') format('woff2'),
         url('fonts/garagegothic-black.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}