@import 'mixins/global';


@media screen and (max-width: 1200px){

	#BurgerMenuBt{
		display: block;
		position: fixed;
		left: 10px;
		top: 10px;
		width: 25px;
		height: 25px;
		background:url("images/icon-menu.svg") no-repeat 0 0;
		background-size: 100% 100%;
		z-index: 10000;
		cursor: pointer;
	}

	#App{
		margin-top: 0px;
	}
	#App #Sidebar{
		position: relative;
		width: 427px;
		margin: 0px;
		height: 260px;
		text-align: center;
		.sidebar_logo{
			width: 335px;
			height: 224px;
			display: inline-block;
		}
		.sidebar_sharebar{
			display: none;
		}
		.sidebar_help{
			display: none;
		}
		.sidebar_footer{
			display: none;
		}
	}

	#App #Main{
		position: relative;
		width: 427px;
		margin: 0px;
		.main_title{
			font-size: 32px;
			line-height: 30px;
			text-align: center;
		}
		.main_sharebarmobile{
			text-align: center;
			display: block;
			margin-top: 10px;
			.sharebar_link{
				width: 42px;
				height: 42px;
				border-radius: 42px;
				display: inline-block;
				vertical-align: top;
				position: relative;
				margin: 0 5px;
				background: $colorMarine;
				&:after{
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					width: 100%;
					height: 100%;
					@include transform(translate(-50%, -50%));
				}
				&[data-link="tw"]{
					&:after{
						background:url("images/icon-white-twt.svg") no-repeat center center transparent;
						background-size: 100% 100%;
						width: 84%;
						height: 84%;
					}
				}
				&[data-link="fb"]{
					&:after{
						background:url("images/icon-white-fb.svg") no-repeat center center transparent;
						background-size: 100% 100%;
						width: 84%;
						height: 84%;
					}
				}
			}
		}
	}

	#App #Main .main_affiches{
		margin-top: 30px;
		.affiche{
			&:before{
				display: none !important;
			}
		}
	}

	#App #Main .main_affiches .affiche[data-mode="landscape"]{
		width: 100%;
		height: auto;
		margin: 0px 0 80px 0;
	}

	#App #Main .main_affiches .affiche[data-mode="portrait"]{
		width: 100%;
		height: auto;
		margin: 0px 0 80px 0;
	}

	#App #Main .main_affiches .affiche .affiche_sharebar{
		display: block;
		top: 100%;
		bottom: auto;
		background: none;
		height: 60px;
	}

	#App #Main .main_affiches .affiche .affiche_sharebar .sharebar_dlbt{
		left: 0px;
	}

	#App #Main .main_affiches .affiche .affiche_sharebar .sharebar_networks{
		right: 0px;
	}

	.dlbt_text{
		display: none;
	}

	#AfficheModalWrapper{
		background:rgba(33, 53, 101, 0.7);
		.modal_bg{
			display: none;
		}
		#AfficheModal{
			height: auto;
			@include transform(translate(-50%, 0));
			margin-top: 100px;
			&.fadein{
				top: 0%;
			}
			.affichemodal_title{
				position: absolute;
				left: 40px;
				@include transform(translate(0,0));
				margin: 0 0 20px 0;
				width: 80% !important;
			}
			.modal_closebt{
				display: block;
				cursor: pointer;
				position: absolute;
				left: 0px;
				bottom: 100%;
				background:url("images/icon-white-close.svg") no-repeat 0 0;
				background-size: 100% 100%;
				width: 28px;
				height: 28px;
				margin-bottom: 20px;
			}
			.affichemodal_img{
				width: 100%;
				min-width: auto;
				height: auto;
			}
			.affichemodal_bar{
				top: 100%;
				margin-top: 30px;
			}
			.affichemodal_src{
				top: 100%;
				margin: 60px 0 100px 0 !important;
				width: 100%;
				max-width: none;
				min-width: none;
				@include transform(translate(-50%, 0));
			}
		}
	}
}

@media screen and (max-width: 440px){
	#App #Sidebar{
		position: relative;
		width: 100%;
		margin: 0px;
		height: 190px;
		.sidebar_logo{
			width: 230px;
			height: 154px;
			display: inline-block;
		}
	}

	#App #Main{
		position: relative;
		width: 100%;
		margin: 0px;
	}

	#App #Main .main_affiches .affiche[data-mode="landscape"]{
		width: 95%;
	}

	#App #Main .main_affiches .affiche[data-mode="portrait"]{
		width: 95%;
	}

	#App #Main .main_affiches .affiche .affiche_sharebar .sharebar_dlbt:before{
		width: 84%;
		height: 84%;
	}

	#App #Main .main_affiches .affiche .affiche_sharebar .sharebar_networks .network_bt{
		width: 35px;
		height: 35px;
		&:before{
			width: 84%;
			height: 84%;
		}
	}

	#AfficheModalWrapper{
		background:rgba(33, 53, 101, 0.7);
		.modal_bg{
			display: none;
		}
	}

	#AfficheModalWrapper #AfficheModal .affichemodal_bar .sharebar_networks .network_bt{
		width: 35px;
		height: 35px;
	}

	#AfficheModalWrapper #AfficheModal .affichemodal_bar .sharebar_dlbt:before{
		width: 35px;
		height: 35px;
	}

	#App #Main .main_sharebarmobile .sharebar_link{
		width: 35px;
		height: 35px;
	}

	#App #Main .main_affiches .affiche .affiche_sharebar .sharebar_dlbt{
		width: 35px;
		height: 35px;
	}

	#AfficheModalWrapper #AfficheModal .affichemodal_bar .sharebar_dlbt{
		width: 35px;
		height: 35px;
	}
}